import React, { useState } from 'react';
import * as el from './TeamStory.styled';
import { CommonGrid } from '../../shared-styled-components/globals.styled';
import home1 from '../../../images/Hero_1.jpg';
import home2 from '../../../images/Hero_2.jpg';
import home3 from '../../../images/Hero_3.jpg';
import home4 from '../../../images/Hero_4.jpg';
import TeamRolePortrait from '../../TeamRolePortrait/TeamRolePortrait.component';
import TeamSlide from '../TeamSlide/TeamSlide.component';
import { generateReactKey } from '../../../utils/base';

import HorizontalScrollContainer from '../../HorizontalScrollContainer/HorizontalScrollContainer.component';

export default function TeamStory() {
  const [currentTeamSlide, setCurrentTeamSlide] = useState(0);

  const roles = [
    {
      label: 'Developer',
      bgSrc: home1,
      headingPart1: 'Developer heading',
      headingPart2: 'we type the code',
      copy:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor earum, optio blanditiis perferendis voluptatibus molestiae ducimus, enim quae assumenda inventore nobis molestias doloribus repellendus ab!',
    },
    {
      label: 'Scrum master',
      bgSrc: home4,
      headingPart1: 'Scrum master',
      headingPart2: 'we organize the team',
      copy:
        'Lorem, ipsum dolor e ducimus, enim quae assumenda inventore nobis molestias doloribus repellendus ab!',
    },
    {
      label: 'QA',
      bgSrc: home2,
      headingPart1: 'Quality Assurance',
      headingPart2: 'we ensure quality',
      copy:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolor earum, optio blanditiis perferendis voluptatibus molestiae ducimus,cdfasdg dfsnobis molestias dolo ribus repellendus ab!',
    },
    {
      label: 'Analytics',
      bgSrc: home3,
      headingPart1: 'Analytics',
      headingPart2: 'we tell you if you achieved anything',
      copy:
        'Lorem, ipsum dolor sit amet coctetur asicing elit. Dolor earum, optio blanditiis perferendis volupte ducimus, enim quae assumenda inventore nobis molestias doibus rependus ab!',
    },
  ];

  return (
    <CommonGrid smFlex>
      <el.TeamSlideContainer>
        <TeamSlide data={roles[currentTeamSlide]} />
      </el.TeamSlideContainer>
      <el.ColorUnderlay />
      <el.TeamContainer>
        <HorizontalScrollContainer>
          {roles.map((role, i) => (
            <TeamRolePortrait
              key={generateReactKey('role', role.label)}
              id={i}
              data={role}
              clickHandler={setCurrentTeamSlide}
            />
          ))}
        </HorizontalScrollContainer>
      </el.TeamContainer>
    </CommonGrid>
  );
}
