import styled, { css, keyframes } from 'styled-components';
import * as vars from '../../../constants/css';
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${vars.gridGutter};
  @media (min-width: ${vars.breakpoints.md}) {
    margin: 0;
    display: grid;
    grid-template-rows: repeat(10, ${vars.commonRowHeight});
    grid-template-columns: repeat(12, 1fr);
  }

  ${p =>
    p.slideState === 'show' &&
    css`
      animation: ${showSlide} 250ms forwards;
    `};

  ${p =>
    p.slideState === 'hide' &&
    css`
      animation: ${hideSlide} 250ms forwards;
    `};
`;

export const HeroImageContainer = styled.div`
  order: 1;
  background: url(${p => p.bgImg}) center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  @media (min-width: ${vars.breakpoints.md}) {
    height: 100%;
    grid-column: 7 / span 6;
    grid-row: 1 / span 10;
  }
`;

export const InfoContainer = styled.div`
  order: 0;
  @media (min-width: ${vars.breakpoints.md}) {
    padding: 0;
    grid-column: 1 / span 5;
    grid-row: 2 / span 9;

    h1 {
      margin-bottom: 40px;
    }
  }
`;

const hideSlide = keyframes`
  from {
    opacity: 1;
    transform: translateX(0)
  }

  to {
    opacity: 0;
    transform: translateX(-100px)
  }
`;

const showSlide = keyframes`
  from {
    opacity: 0;
    transform: translateX(100px)
  }

  to {
    opacity: 1;
    transform: translateX(0)
  }
`;
