import styled from 'styled-components';
import * as vars from '../../../constants/css';

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${vars.breakpoints.md}) {
    display: grid;
    grid-gap: ${vars.gridGutter};
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, 100px);
  }
`;

export const Col1 = styled.div`
  grid-column: 2 / span 3;
  grid-row: 1 / span 3;
`;

export const Col2 = styled.div`
  grid-column: 5 / span 3;
  grid-row: 1 / span 3;
`;

export const Col3 = styled.div`
  grid-column: 8 / span 3;
  grid-row: 1 / span 3;
`;
