import styled from 'styled-components';
import * as vars from '../../../constants/css';

export const TopGrid = styled.div`
  display: flex;
  flex-direction: column;
  padding: 200px ${vars.gridGutter};
  margin: -200px 0 0 0;
  background-color: ${vars.branding.paleGreen};
  @media (min-width: ${vars.breakpoints.md}) {
    background: transparent;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(4, 75px);
    grid-gap: ${vars.gridGutter};
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-template-rows: repeat(4, 50px);
  }
`;

export const Cell1 = styled.div`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 6;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 1 / span 2;
    grid-column: 1 / span 4;
  }
`;

export const Cell2 = styled.div`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 2 / span 2;
    grid-column: 7 / span 6;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 2 / span 2;
    grid-column: 5 / span 6;
  }
`;

export const BottomGridContainer = styled.div`
  z-index: 10;
  margin: -200px 0 0 0;
  @media (min-width: ${vars.breakpoints.md}) {
    margin: 0;
  }
`;

export const BottomGrid = styled.div`
  display: flex;
  z-index: 10;
  @media (min-width: ${vars.breakpoints.md}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, 100px);
    grid-gap: ${vars.gridGutter};
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-template-rows: repeat(5, 100px);
  }
`;

const Card = styled.div`
  padding: ${vars.gridGutter};
  border-radius: 5px;
  box-shadow: 0px 0px 4px 1px rgba(1, 0, 0, 0.2);
  width: 250px;
  flex-shrink: 0;
  margin: ${vars.gridGutter} 10px;
  background: #fff;
  @media (min-width: ${vars.breakpoints.md}) {
    background: transparent;
    width: auto;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
  }
`;

export const Cell3 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 3;
    grid-column: 1 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 1 / span 3;
    grid-column: 2 / span 3;
  }
`;

export const Cell4 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 3;
    grid-column: 5 / span 4;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 1 / span 3;
    grid-column: 5 / span 3;
  }
`;

export const Cell5 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 1 / span 3;
    grid-column: 9 / span 4;
  }

  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 1 / span 3;
    grid-column: 8 / span 3;
  }
`;

export const Cell6 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 4 / span 3;
    grid-column: 1 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 2 / span 3;
  }
`;

export const Cell7 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 4 / span 3;
    grid-column: 5 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 5 / span 3;
  }
`;

export const Cell8 = styled(Card)`
  @media (min-width: ${vars.breakpoints.md}) {
    grid-row: 4 / span 3;
    grid-column: 9 / span 4;
  }
  @media (min-width: ${vars.breakpoints.lg}) {
    grid-row: 3 / span 3;
    grid-column: 8 / span 3;
  }
`;
