import React from 'react';
import * as el from './WhatWeDo.styled';
import { Heading, P } from '../../shared-styled-components/typography';
import { GlobalWrapper } from '../../shared-styled-components/globals.styled';
import HorizontalScrollContainer from '../../HorizontalScrollContainer/HorizontalScrollContainer.component';

export default function WhatWeDo() {
  return (
    <GlobalWrapper>
      <el.TopGrid>
        <el.Cell1>
          <Heading capitalized>What we do</Heading>
          <Heading spacedOut medium>
            We foster digitally engaged relationships with our clients by
            grounding ourselves in customer insights.
          </Heading>
        </el.Cell1>
        <el.Cell2>
          <P spacedOut>
            We bring excellence to Intact Financial Corporation's Ecosystem by
            striving for a seamless omnichannel customer experience, and we are
            commited to challenging to insurance industry's status quo through
            data-driven, customercentric offerings
          </P>
        </el.Cell2>
      </el.TopGrid>
      <el.BottomGridContainer>
        <HorizontalScrollContainer>
          <el.BottomGrid>
            <el.Cell3>
              <Heading margin="0 0 10px 0" medium>
                Artificial intelligence
              </Heading>
              <P spacedOut>
                From concept to execution, we create digital campaigns from
                scratch and show you the way to expand your online presence and
                make it shine.
              </P>
            </el.Cell3>
            <el.Cell4>
              <Heading margin="0 0 10px 0" medium>
                Design
              </Heading>
              <P spacedOut>
                As a diverse team of researchers, strategists, designers, and
                wrtiers, we're focused on improving current features and
                imagining new futures for our technology through magical user
                experiences.
              </P>
            </el.Cell4>
            <el.Cell5>
              <Heading margin="0 0 10px 0" medium>
                Development
              </Heading>
              <P spacedOut>
                Beauty is nothing without usability and that's why our in-house
                devs work together with creatives. We use the latest technology
                and we never stop learning.
              </P>
            </el.Cell5>
            <el.Cell6>
              <Heading margin="0 0 10px 0" medium>
                Business Analysis
              </Heading>
              <P spacedOut>
                From concept to execution, we create digital campaigns from
                scratch and show you he way to expand your online presence and
                make it shine.
              </P>
            </el.Cell6>
            <el.Cell7>
              <Heading margin="0 0 10px 0" medium>
                Data Science
              </Heading>
              <P spacedOut>
                From concept to execution, we create digital campaigns from
                scatch and show you the way to expand your online presence and
                make it shine.
              </P>
            </el.Cell7>
            <el.Cell8>
              <Heading margin="0 0 10px 0" medium>
                Strategy
              </Heading>
              <P spacedOut>
                From concept to execution, we create digital campaigns from
                scratch and show you the way to expand your online presence and
                make it shine.
              </P>
            </el.Cell8>
          </el.BottomGrid>
        </HorizontalScrollContainer>
      </el.BottomGridContainer>
    </GlobalWrapper>
  );
}
