import React from 'react';
// import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
// import GrabACoffee from '../components/team/GrabACoffee/GrabACoffee.component';
import WeValue from '../components/team/WeValue/WeValue.component';
import WhatWeDo from '../components/team/WhatWeDo/WhatWeDo.component';
import OurMindset from '../components/team/OurMindset/OurMindset.component';
import TeamStory from '../components/team/TeamStory/TeamStory.component';

const TeamPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="Team page" />
    <TeamStory />
    <WhatWeDo />
    <OurMindset />
    <WeValue />
    {/*  <GrabACoffee /> */}
  </Layout>
);

export default TeamPage;
