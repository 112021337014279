import React from 'react';
import * as el from './WeValue.styled';
import { Heading, P } from '../../shared-styled-components/typography';
import {
  GlobalWrapper
  // CommonGrid,
} from '../../shared-styled-components/globals.styled';

export default function WeValue() {
  return (
    <GlobalWrapper as="section">
      <Heading capitalized as="h4">
        We Value
      </Heading>
      <el.Grid>
        <el.Col1>
          <Heading medium>Empathetic Dreamers</Heading>
          <P spacedOut>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Soluta,
            illo?
          </P>
        </el.Col1>
        <el.Col2>
          <Heading medium>Collaborative Partners</Heading>
          <P spacedOut>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio
            ab soluta omnis sint animi. Facere!
          </P>
        </el.Col2>
        <el.Col3>
          <Heading medium>Fast Learner</Heading>
          <P spacedOut>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
            quia eos accusamus eligendi reiciendis exercitationem sint ducimus.
            Nam, non est!
          </P>
        </el.Col3>
      </el.Grid>
    </GlobalWrapper>
  );
}
