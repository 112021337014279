import styled from 'styled-components';
import * as vars from '../../constants/css';
export const TeamRoleContainer = styled.button`
  width: 100px;
  height: 100px;
  transition: all 200ms ease-out;
  border: none;
  border-radius: 999px;
  flex-shrink: 0;
  padding: 0;
  position: relative;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    background: ${vars.branding.primaryLight};
  }

  @media (min-width: ${vars.breakpoints.md}) {
    width: 190px;
    height: 190px;
  }
`;

export const ColorUnderlay = styled.div`
  /* CENTER X & Y ABSOLUTLE-Y HACK,  */
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  /*  /CENTER X & Y ABSOLUTLE-Y HACK,  */
  border-radius: 999px;
  background: linear-gradient(to right top, #01b5bf, #e2f0f1);
  transition: all 500ms ease-out;
  ${TeamRoleContainer}:hover & {
    transform: rotate(180deg);
  }

  @media (min-width: ${vars.breakpoints.md}) {
    /* CENTER X & Y ABSOLUTLE-Y HACK,  */
    width: 190px;
    height: 190px;
    margin: -95px 0 0 -95px;
    /*  /CENTER X & Y ABSOLUTLE-Y HACK,  */
  }
`;

export const ImageContainer = styled.span`
  /* CENTER X & Y ABSOLUTLE-Y HACK,  */
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -48px 0 0 -48px;
  /* /CENTER X & Y ABSOLUTLE-Y HACK,  */
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-width 200ms ease-out;
  border: #fff 2px solid;
  background: url(${p => p.bgSrc}) center center no-repeat;
  background-size: cover;
  z-index: 999;

  @media (min-width: ${vars.breakpoints.md}) {
    /* CENTER X & Y ABSOLUTLE-Y HACK,  */
    width: 182px;
    height: 182px;
    margin: -91px 0 0 -91px;
    /* /CENTER X & Y ABSOLUTLE-Y HACK,  */
  }
`;

export const Label = styled.span`
  text-transform: uppercase;
  display: inline-block;
  padding: 2px;
  font-weight: 700;
  transition: color 200ms ease-out;
  color: ${vars.branding.primary};
  background: rgba(255, 255, 255, 0.8);
  ${TeamRoleContainer}:hover & {
    color: ${vars.branding.primaryLight};
  }
`;
