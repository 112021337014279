import React, { useEffect, useState } from 'react';
import * as el from './TeamSlide.styled';
import {
  Heading,
  Emphasis,
  P,
} from '../../shared-styled-components/typography';

export default function TeamSlide(props) {
  const [currentSlideData, setCurrentSlideData] = useState({
    headingPart1: null,
    headingPart2: null,
    copy: null,
    bgSrc: null,
  });

  const [slideState, setSlideState] = useState('show');

  useEffect(() => {
    if (props.data.copy !== currentSlideData.copy) {
      setSlideState('hide');
      setTimeout(() => {
        setCurrentSlideData({ ...props.data });
        setSlideState('show');
      }, 500);
    }
  }, [props.data, currentSlideData.copy]);
  return (
    <el.Grid slideState={slideState}>
      <el.HeroImageContainer bgImg={currentSlideData.bgSrc} />
      <el.InfoContainer>
        <Heading as="h1" large light>
          {currentSlideData.headingPart1}
          <br />
          <Emphasis>{currentSlideData.headingPart2}</Emphasis>
        </Heading>
        <P heroText>{currentSlideData.copy}</P>
      </el.InfoContainer>
    </el.Grid>
  );
}
