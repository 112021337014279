import React from 'react';
import * as el from './HorizontalScrollContainer.styled';

export default function HorizontalScrollContainer(props) {
  return (
    <el.ScrollContainer>
      <el.ContentContainer>{props.children}</el.ContentContainer>
    </el.ScrollContainer>
  );
}
