import styled from 'styled-components';
import * as vars from '../../../constants/css';

export const TeamSlideContainer = styled.div`
  order: 2;
  @media (min-width: ${vars.breakpoints.md}) {
    grid-column: 2 / span 10;
    z-index: 10;
  }
`;
export const ColorUnderlay = styled.div`
  display: none;
  @media (min-width: ${vars.breakpoints.md}) {
    display: block;
    background: ${vars.branding.paleGreen};
    grid-column: 9 / span 4;
    grid-row: 2 / span 11;
  }
`;
export const TeamContainer = styled.div`
  order: 1;
  margin: ${vars.gridGutter} ${vars.gridGutter} 0 ${vars.gridGutter};
  z-index: 15;
  @media (min-width: ${vars.breakpoints.md}) {
    margin: 0;
    grid-column: 2 / span 10;
    grid-row: 8 / span 4;
  }
`;
