import React from 'react';
import * as el from './TeamRolePortrait.styled';

export default function TeamRolePortrait(props) {
  return (
    <el.TeamRoleContainer onClick={() => props.clickHandler(props.id)}>
      <el.ColorUnderlay />
      <el.ImageContainer bgSrc={props.data.bgSrc}>
        <el.Label>{props.data.label}</el.Label>
      </el.ImageContainer>
    </el.TeamRoleContainer>
  );
}
